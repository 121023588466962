import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container mx-auto p-4 my-16">
      <h1 className="text-4xl font-extrabold">Not found</h1>
      <div className="container mx-auto p-4 my-16 bg-gray-400">
        <p>Just like Boris Johnson's integrity....</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
